<template>
  <docs-layout>
    <p>Default navbar</p>
  </docs-layout>
  <asom-navbar
    :links="sampleLinks1"
  />
  <!-- <asom-container class="text-left my-4">
    <p>Navbar with dropdown links</p>
  </asom-container>
  <asom-navbar
    :links="sampleLinks2"
  /> -->
</template>

<script>
import DocsLayout from '@/docs/DocsLayout.vue'

export default {
  components: {
    DocsLayout,
  },
  computed: {
    sampleLinks1() {
      return [
        { text: 'Current Page', 'href': '#', icon: 'home-alt' },
        { text: 'Link 1', 'href': '/sample1', icon: 'alarm' },
        { text: 'Link 2', 'href': '/sample2', icon: "info" },
        { text: 'Link 3', 'href': '/sample3' },
        { text: 'Link 4', 'href': '/sample4', icon: 'inbox' },
      ]
    },
    sampleLinks2() {
      return [
        { text: 'Dashboard', 'href': '#', 'current': true },
        { text: 'Link 1', 'href': '#' },
        { text: 'Link 2', 'href': '#' },
        { text: 'Link 3', 'href': '#' },
        // { text: 'Link 4',
        //   children: [
        //     { text: 'Link 1', 'href': '#' },
        //     { text: 'Link 2', 'href': '#' },
        //     { text: 'Link 3', 'href': '#' },
        //     { text: 'Link 4', 'href': '#' },
        //   ]
        // },
      ]
    }
  }
}
</script>

<style>

</style>